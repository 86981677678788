@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
.mainStyle{
    background-color: black;
}

h5, h3, h2, h1 {
    color: white;
    font-family: "Inter";
}

.modal-title{
    color: black !important;
}

p {
    color: white;
}

label {
    color: white;
    font-family: "Inter";
    text-decoration: underline;
}

a {
    color: #66e877 !important;
}

th, td {
    text-align: center;
}

ul, li { 
    margin-left: 0; 
    padding-left: 0; 
    list-style-type: none;
}

select {
    margin: 0 5px !important;
}

.bandphoto{
    max-width: 305px !important;
    height: auto;
}

.venueDiv{
    transition: all .2s ease-in-out;
    border-radius: 10px;
    max-width: 1000px;
}

.venueDiv:hover{
    box-shadow: 0px 0px 13px 11px #7b8e70;
    transform: scale(1.1);
}

.venueSearch{
    max-width: 200px;
    border-radius: 4px;
}

.smallstars{
    font-size: 1em;
    color: #94b184;
}

table {
    background-color: none!;
}

th,td {
    color: white ;
    word-wrap: break-word;
}
.bandbio{
    max-width: 560px;
}

.artistCreateButton{
    background-color: #333a2f;
    color: white;
    border-radius: 5px;
    border: 1px solid white;
    font-size: 20px;
    padding: 4px;
    margin-top: 5px;
}

.artistUpdateButton{
    background-color: #333a2f;
    color: white;
    border-radius: 5px;
    border: 1px solid white;
    font-size: 20px;
    padding: 4px;
    margin-top: 5px;
}

.formcontent {
    background-color: #86967d;
    border: 2px solid black;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 9px;
}

.navbutton {
    color: white;
    font-size: 20px;
    padding: 4px;
    
}

.nav-item{
    border-left: 2px solid white;
}


.nav-item:nth-child(3){
    border-right: 2px solid white;
}

.homeaccountbutton{
    background-color: #637558;
    color: white;
    border-radius: 5px;
    border: 1px solid white;
    font-size: 20px;
    padding: 4px;
}

.createReviewbtn{
    color: white !important;
}

.apiVenueReviews{
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.apitext{
    min-width: 250px;
}

.homeaccountbutton:hover {
    background-color: #405a31;
}

.artistCreateButton:hover {
    background-color: #405a31;
}

.artistUpdateButton:hover {
    background-color: #405a31;
}

.navbutton:hover {
    text-decoration: underline;
}

.venueSearchBtn{
    background-color: #829775;
    color:white;
    border-radius: 5px;
}

.venueSearchBtn:hover{
    background-color: #b8d2a8;
}

.bg-dark{
   background-image: linear-gradient(to right, rgba(255,0,0,0), rgb(142 165 125)) !important;
   box-shadow: inset -3em 0em 20px 0px #91ae80 !important;
}

.fa-times {
    margin-left: 7px;
    color: #61DD71;
}

.fa-plus {
    margin-right: 7px;
    color: #61DD71;
}

.fa-pencil {
    font-size: 25px;
    margin-left: 10px;
}

.fa-pencil:hover, .fa-times:hover, .fa-plus:hover {
    cursor: pointer;
}
